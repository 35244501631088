<template>
  <div class="member-videos-category">
    <div>
      <h2 class="inline-block heading-big mb-3">{{ categoryLabel }}</h2>
      <router-link
        v-if="categoryID"
        :to="'/videos/category/' + categoryID"
        class="member-videos-category-lnk lnk-dark lnk-dark-big text-nowrap ml-3">
        See All >
      </router-link>
    </div>
    <div class="list-wrapper relative">
      <ul
        class="member-videos-list flex"
        :data-current="currentItem"
        :data-count="videos.length"
        :id="'member-videos-list-' + categoryID"
        :style="{ transform: 'translateX(-' + (currentListOffset) + 'px)' }">
        <li
          v-for="(video, videoIndex) in videos"
          :key="categoryID + '-video-' + videoIndex"
          class="member-videos-list-item">
          <router-link
            class="member-video"
            :to="'/videos/video/' + video.id">
            <div class="video-thumbnail">
              <img
                class="video-thumbnail-img block"
                :src="video.image ? video.image : require('@/assets/images/video-default-thumbnail.jpg')"
                alt="Video Thumbnail" />
              <div class="video-play-btn">
                <div class="video-play-btn-triangle"></div>
              </div>
            </div>
            <h3 class="video-heading heading-medium mt-2 mb-0">
              {{ video.title }}
            </h3>
            <p class="video-author my-0">{{ video.name }}</p>
          </router-link>
        </li>
      </ul>
      <a
        @click.prevent="showPrevItem"
        href="#prev"
        :class="{
          'arrow arrow-previous': true,
          'is-disabled': !isArrowPrevVisible
        }">
        &laquo;
      </a>
      <a
        @click.prevent="showNextItem"
        href="#next"
        :class="{
          'arrow arrow-next': true,
          'is-disabled': !isArrowNextVisible
        }">
        &raquo;
      </a>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';

export default {
  name: 'videos-slider',
  props: {
    categoryLabel: {
      type: String,
      required: true
    },
    categoryID: {
      default: 0
    },
    videos: {
      type: Array,
      required: true
    },
    windowWidth: {
      default: 0
    },
  },
  computed: {
    sliderList () {
      return document.getElementById(this.listID);
    },
    listFullWidth () {
      return this.itemWidth * this.videos.length;
    },
    itemWidth () {
      if (!this.sliderList) {
        return 0;
      }
      let item = this.sliderList.querySelector('.member-videos-list-item');
      let itemStyle = window.getComputedStyle(item);
      return item.offsetWidth + parseFloat(itemStyle.marginLeft) + parseFloat(itemStyle.marginRight);
    },
    componentWidth () {
      // TODO: is there another way to approach
      if (!this.windowWidth || !this.sliderList) {
        return 0;
      }
      return this.sliderList.parentElement.offsetWidth;
    },
    isArrowPrevVisible () {
      if ((this.currentItem === 0) || (this.listFullWidth <= this.componentWidth)) {
        return false;
      }

      return true;
    },
    isArrowNextVisible () {
      if (this.listFullWidth <= this.componentWidth) {
        return false;
      }

      if (this.currentListOffset >= (this.listFullWidth - this.componentWidth)) {
        return false;
      }

      return true;
    },
    currentListOffset () {
      return this.currentItem * this.itemWidth;
    }
  },
  data () {
    return {
      currentItem: 0,
      listID: '',
      dummyWindowWidth: 0
    }
  },
  mounted () {
    this.listID = 'member-videos-list-' + this.categoryID;
  },
  methods: {
    showNextItem () {
      if ((this.currentItem * this.itemWidth) < (this.listFullWidth - this.componentWidth)) {
        Vue.set(this, 'currentItem', this.currentItem + 1);
      }
    },
    showPrevItem () {
      if (this.currentItem > 0) {
        Vue.set(this, 'currentItem', this.currentItem - 1);
      }
    }
  }
}
</script>
