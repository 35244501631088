<template>
  <div
    :class="{
      'member-videos-view': true,
      'lates-videos-view': !isSingleType
    }">
    <validation-observer
      ref="member-videos-filters-form"
      tag="form"
      class="search-form flex flex-align-items-center mb-5">
      <f-input-wrapper
        prependIcon="search"
        class="mr-2 full-width">
        <input
          type="search"
          v-model="filters.search"
          placeholder="Search Video Library"/>
      </f-input-wrapper>

      <div class="search-form-buttons flex ml-auto">
        <f-button
          theme="primary"
          :big="true"
          class="m2-1 mb-1-ms ml-0-sm text-nowrap"
          @click.prevent="validateFiltersForm">
          Search
        </f-button>

        <f-button
          theme="warning"
          :big="true"
          class="ml-1 text-nowrap"
          :disabled="resetFiltersDisabled"
          @click.prevent="clearFilters">
          Reset filters
        </f-button>
      </div>
    </validation-observer>

    <div
      v-if="allDataLoaded"
      class="member-videos pb-5">
      <template v-if="!isSingleType">
        <template
          v-for="(category, categoryIndex) in videos">
          <videos-slider
            v-if="category.files.length"
            :key="categoryIndex"
            :categoryLabel="category.label"
            :categoryID="category.id"
            :videos="category.files"
            :windowWidth="windowWidth" />
        </template>
      </template>
      <template v-else>
        <div class="member-videos-category">
          <div class="mb-3">
            <h2 class="inline-block heading-big mb-1">
              <template v-if="isSearchResults">
                Search results for: "{{ filters.search }}"
                <template v-if="filters.categoryID"> in "{{ videos.label }}"</template>
              </template>
              <template v-else>
                {{ videos.label }}
              </template>
            </h2>
            <div
              v-if="tagsLoaded && allTags.length && filters.tags.length"
              class="flex flex-align-items-center">
              <span>Only videos with tag: </span>
              <ul class="video-tags my-0 ml-1">
                <li
                  v-for="(tag, tagIndex) in filters.tags"
                  :key="tagIndex"
                  class="video-tags-item is-small">
                  {{ getTagName(tag) }}
                </li>
              </ul>
            </div>
          </div>
          <template v-if="videos.files.length">
            <ul class="member-videos-list flex flex-wrap">
              <li
                v-for="(video, videoIndex) in videos.files"
                :key="'video' + videoIndex"
                class="member-videos-list-item">
                <router-link
                  class="member-video"
                  :to="'/videos/video/' + video.id">
                  <div class="video-thumbnail">
                    <img
                      class="video-thumbnail-img block"
                      :src="video.image ? video.image : require('@/assets/images/video-default-thumbnail.jpg')"
                      alt="Video Thumbnail" />
                    <div class="video-play-btn">
                      <div class="video-play-btn-triangle"></div>
                    </div>
                  </div>
                  <h3 class="video-heading heading-medium mt-2 mb-0">
                    {{ video.title }}
                  </h3>
                  <p class="video-author my-0">{{ video.name }}</p>
                </router-link>
              </li>
            </ul>
          </template>
          <template v-else>
            <p>There are no videos to display.</p>
          </template>
        </div>
      </template>
    </div>
    <div
      v-if="!allDataLoaded && !loadError && !userNotActiveError"
      class="loading-spinner-wrapper">
      <div class="loading-spinner pb-3 pt-3">
        <span class="loading-spinner-content">
          Loading data&hellip;
        </span>
      </div>
    </div>

    <f-alert
      v-if="userNotActiveError"
      visible
      :centered="true"
      class="my-5"
      theme="danger">
      <p>
      <span>
        Videos are available for active users only.
      </span><br>
      <span>
        You can activate Your membership by going to
        <router-link
          to="/my-account/membership-plan"
          class="lnk lnk-alert">
          My Account
        </router-link>.
      </span>
      </p>
    </f-alert>

    <f-alert
      v-if="loadError"
      visible
      class="my-5"
      theme="warning">
      <span>
        Loading data failed.
        <a
          href="javascript:window.location.reload();"
          class="lnk lnk-alert">
          Reload page
        </a>
        to try again.
      </span>
    </f-alert>
  </div>
</template>
<script>
import debounce from 'lodash.debounce';
import FormUtils from '@/utils/FormUtils.js';
import ListUtils from '@/utils/ListUtils.js';
import HasFilters from '@/mixins/HasFilters.vue';
import VideosSlider from '@/components/layout/VideosSlider.vue';

export default {
  name: 'member-videos-list',
  mixins: [
    HasFilters
  ],
  components: {
    'videos-slider': VideosSlider
  },
  computed: {
    allDataLoaded () {
      return this.loaded;
    },
    resetFiltersDisabled () {
      return this.filters.search === '';
    }
  },
  data () {
    return {
      videos: [],
      loaded: false,
      loadError: false,
      filters: {
        categoryID: 0,
        search: '',
        tags: []
      },
      isSingleType: false,
      isSearchResults: false,
      allTags: [],
      tagsLoaded: false,
      windowWidth: 0,
      userNotActiveError: false
    };
  },
  mounted () {
    window.addEventListener('resize', this.debouncedResizeHandler);
    this.$bus.$on('update-videos-list-params', this.updateVideosListParams);
    this.$bus.$on('reset-videos-list-params', this.resetVideosListParams);

    this.windowWidth = window.innerWidth

    if (this.$route.params && this.$route.params.categoryID) {
      if (this.$route.params.categoryID) {
        this.filters.categoryID = this.$route.params.categoryID;
      }
    }

    if (this.$route.query.search) {
      this.filters.search = this.$route.query.search;
    }

    this.loadAdditionalData();
    this.filters.tags = this.$store.getters['getMemberVideosTags'];
    this.loadFilteredData();
  },
  methods: {
    debouncedLoadFilteredData: debounce(function () {
      this.$bus.$emit('view-filters-save');
      this.loadFilteredData();
    }, 250),
    loadFilteredData () {
      this.loaded = false;
      this.isSingleType = false;
      this.isSearchResults = false;

      let requestParams = {
        is_pagination: 0,
        page: this.currentPage
      };

      if (this.filters.search && this.filters.search.length) {
        this.isSingleType = true;
        this.isSearchResults = true;
        requestParams.search = this.filters.search;
      }

      if (this.filters.tags && this.filters.tags.length) {
        requestParams.tags = this.filters.tags;
      }

      if (this.filters.categoryID) {
        this.isSingleType = true;
        requestParams.category_id = this.filters.categoryID;
      }

      ListUtils.loadItemsData(this, {
        endpoint: '/api/member/video/home-page',
        method: 'get',
        listField: 'videos',
        params: requestParams,
        successAction: (response) => {
          if (response.data && !response.data.data) {
            this.loaded = false;

            if (response.data.error === 'userNotActive') {
              this.userNotActiveError = true;
              return;
            }

            this.loadError = true;
          }

          this.userNotActiveError = false;
        }
      });
    },
    validateFiltersForm () {
      this.$bus.$emit('pagination-reset');
      FormUtils.validate(this.$refs['member-videos-filters-form'], this.debouncedLoadFilteredData);
    },
    clearFilters () {
      this.loaded = false;
      this.loadError = false;
      this.filters = {
        search: ''
      };

      this.loadFilteredData();
      this.$bus.$emit('view-filters-reset');
    },
    updateVideosListParams (value, params) {
      let tagCurrentIndex = this.filters.tags.indexOf(params.tag);
      if (params.tag) {
        if (tagCurrentIndex < 0 && value) {
          this.filters.tags.push(params.tag);
        } else if (tagCurrentIndex >= 0 && !value) {
          this.filters.tags.splice(tagCurrentIndex, 1);
        }
      }

      this.$store.commit('setMemberVideosTags', this.filters.tags);

      this.loadFilteredData();
    },
    resetVideosListParams () {
      this.filters.tags.splice(0);
      this.loadFilteredData();
    },
    loadAdditionalData () {
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/member/video/tag/get-all',
        method: 'get',
        outputKey: 'allTags',
        loadedKey: 'tagsLoaded',
        errorKey: 'loadError'
      });
    },
    getTagName (tagID) {
      let tag = this.allTags.find(item => item.id === tagID);
      if (tag && tag.name) {
        return tag.name;
      }

      return '';
    },
    debouncedResizeHandler: debounce(function () {
      this.windowWidth = window.innerWidth;
    }, 250)
  },
  beforeDestroy () {
    this.$bus.$off('update-videos-list-params', this.updateVideosListParams);
    this.$bus.$off('reset-videos-list-params', this.resetVideosListParams);
    window.removeEventListener('resize', this.debouncedResizeHandler);
  }
}
</script>
